import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 导入树形表格
import TreeTable from 'vue-table-with-tree-grid'
// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入NProgress包对应的js和css
import NProgress from 'nprogress'
import axios from 'axios'
axios.defaults.baseURL = 'http://81.68.122.236:4000/api/private/v1/' // 设置axios基准地址
// 在request拦截器中，展示进度条 NProgress.start()
axios.interceptors.request.use(function (config) {
  NProgress.start()
  // 在发送请求之前，带上token身份，证明是登录过的
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
// 在response拦截器中，隐藏进度条 NProgress.done()
axios.interceptors.response.use(function (config) {
  NProgress.done()
  return config
})
Vue.prototype.$http = axios // 把axios添加到Vue的prototype上，以后所有vue实例都可以使用axios
Vue.config.productionTip = false
Vue.component('tree-table', TreeTable)
Vue.use(VueQuillEditor)
// 时间过滤器
Vue.filter('dateFormat', function (val) {
  const dt = new Date(val)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
